@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@800&display=swap');

* {
    margin: 0px 0px 0px 0px;
}


textarea:focus,
input:focus,
select:focus {
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
}



.form-group {
    select {
        display: none;
    }

    position: relative;


    &:focus .form-input,
    &:hover .form-input {
        border-radius: 10px;
        background-image: linear-gradient(white, white),
            radial-gradient(circle at top left,
                rgba(0, 29, 69, 1),
                rgba(43, 129, 173, 1));
        background-origin: border-box;
        background-clip: padding-box, border-box;
    }

    &:hover .form-label,
    &:focus .form-label {
        color: black;
    }


}

.form-label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    overflow-y: hidden;
    z-index: 1;
    transition: all 0.5s ease;
    display: block;
    position: relative;
    left: 5px;
    right: 0%;
    top: -16px;
    line-height: 120%;
    width: max-content;
    background-color: #f5f5f5;

    ::placeholder {
        position: absolute;
        left: 6.07%;
        right: 0.91%;
        top: 30.1%;
        bottom: 27.59%;
        font-size: 14px;
        line-height: 120%;
    }

    &.focus {
        transform: translateY(-130%);
        -moz-transform: translateY(-130%);
        -webkit-transform: translateY(-130%);
        -ms-transform: translateY(-130%);
        font-size: 1.25em;
    }
}

.form-input {
    position: relative;
    height: 36px;
    width: 17.917vw;
    top: 9px;
    border-radius: 10px;

    &:valid+.form-label {
        transform: translateY(-130%);
        -moz-transform: translateY(-130%);
        -webkit-transform: translateY(-130%);
        -ms-transform: translateY(-130%);
        font-size: 0.8em;
    }

    &:focus+.form-label {
        transform: translateY(-130%);
        -moz-transform: translateY(-130%);
        -webkit-transform: translateY(-130%);
        -ms-transform: translateY(-130%);
        font-size: 0.8em;
    }

    &-select {
        @extend .form-input;

        &-focus {

            border-radius: 10px;
            background-image: linear-gradient(white, white),
                radial-gradient(circle at top left,
                    rgba(0, 29, 69, 1),
                    rgba(43, 129, 173, 1));
            background-origin: border-box;
            background-clip: padding-box, border-box;
        }
    }
}
